import axios from "axios";

import createStoreModule from "shared/lib/createStoreModule.js";
import sortable from "shared/lib/sortable.js";
export default createStoreModule("block", {
  ...sortable("block", {
    actions: {
      async allByPage({ commit, state, getters, rootState }, pageSlug) {
        const url = `/hubs/${rootState.hub.current.slug}/pages/${pageSlug}/blocks.json`;
        try {
          const response = await axios.get(url);
          commit("setAll", response.data);
          return response.data;
        } catch (e) {
          if (e.response) {
            return e.response.data;
          }
          console.error(e);
        }
      },
    },
  }),
});
