import Vue from "vue";
import axios from "axios";

import store from "hub/store";
import min from "lodash/min";
import max from "lodash/max";

import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

import addToUniqueArray from "shared/lib/addToUniqueArray";

import Search from "lib/search.js";

const cacheKey = function (filter, q) {
  let out = filter;
  if (q) {
    out = filter + q;
  }
  return out;
};

export default {
  namespaced: true,
  state: {
    all: {},
    current: null,
    editingTitle: false,
    gets: {},
    followingIds: [],
    mutingIds: [],
    mores: {},
    views: {},
    lastView: "",
  },
  mutations: {
    add(state, user) {
      Vue.set(state.all, `${user.id}`, { ...user, ...state.all[`${user.id}`] });
    },
    addToView(state, payload) {
      Vue.set(
        state.views,
        payload.key,
        (state.views[payload.key] || []).concat(payload.ids)
      );
    },
    update(state, user) {
      Vue.set(state.all, `${user.id}`, user);
    },
    hide(state, userId) {
      Vue.delete(state.all, `${userId}`);
    },
    set(state, user) {
      state.current = user;
    },
    setGets(state, key) {
      Vue.set(state.gets, key, true);
    },
    setEditingTitle(state, val) {
      state.editingTitle = val;
    },
    setFollowing(state, user_id) {
      if (!state.followingIds.includes(user_id)) {
        state.followingIds.push(user_id);
      }
    },
    setFollowingIds(state, ids) {
      state.followingIds = ids;
    },
    setLastView(state, key) {
      state.lastView = key;
    },
    setMuting(state, userId) {
      state.mutingIds = addToUniqueArray(state.mutingIds, userId);
    },
    setMutingIds(state, ids) {
      state.mutingIds = ids;
    },
    setMore(state, payload) {
      Vue.set(state.mores, payload.key, payload.value);
    },
    removeFollowingId(state, id) {
      state.followingIds.splice(state.followingIds.indexOf(id), 1);
    },
    removeMuting(state, userId) {
      const index = state.mutingIds.indexOf(userId);
      if (index > -1) {
        state.mutingIds.splice(index, 1);
        store.dispatch("post/setPostMuteValues", {
          mutingIds: state.mutingIds,
        });
      }
    },
  },
  getters: {
    collaborators(state, getters, rootState) {
      return filter(getters["sorted"], (user) => {
        return user.collaborator;
      });
    },

    current(state) {
      return state.current;
    },
    followingIdsIncludingSelf(state) {
      return state.followingIds.concat([state.current.id]);
    },

    followers(state, getters, rootState) {
      return filter(getters["sorted"], (user) => {
        return user.follower;
      });
    },

    followings(state, getters, rootState) {
      return filter(getters["sorted"], (user) => {
        return getters["isFollowing"](user.id);
      });
    },

    isFollowing: (state, getters) => (user_id) => {
      return state.followingIds.includes(user_id);
    },

    min: (state, getters) => (filter, q) => {
      const ids = getters["view"](filter, q).map((user) => {
        return user.participation_id;
      });
      return min(ids);
    },

    more: (state, getters) => (filter) => {
      const more = state.mores[cacheKey(filter)];
      if (typeof more != "undefined") {
        return more;
      }
      return true;
    },

    sorted(state) {
      return orderBy(state.all, "participation_id", ["desc"]);
    },

    url: (state, getters, rootState) => (filter) => {
      const basePath = `/attendee/hubs/${rootState.hub.current.slug}`;
      if (filter === "followers") {
        return `${basePath}/followers.json?items=25`;
      }
      if (filter === "followings") {
        return `${basePath}/followings.json?items=25`;
      }
      if (filter === "collaborators") {
        return `${basePath}/team.json`;
      }
      return `${basePath}/participants.json?items=25`;
    },

    view:
      (state, getters) =>
      (filter = "", q = "") => {
        const lastView = state["lastView"];
        const key = cacheKey(filter, q);

        const ids = state.views[key];
        let set = getters["sorted"];

        if (filter === "followers") {
          set = getters["followers"];
        }
        if (filter === "followings") {
          set = getters["followings"];
        }
        if (filter === "collaborators") {
          set = getters["collaborators"];
        }

        let out = [];

        if (ids) {
          out = set.filter((user) => ids.includes(user.id));
        } else if (lastView) {
          const lastIds = state.views[lastView];
          out = set.filter((user) => lastIds.includes(user.id));
        }

        if (q) {
          const search = new Search(out, ["name", "username"]);
          const results = search.perform(q);
          return results;
        } else {
          return out;
        }
      },
  },

  actions: {
    async all({ commit, state, getters, rootState }, payload) {
      const url = getters["url"](payload.filter);
      const key = cacheKey(payload.filter, payload.q);
      const min = getters["min"](payload.filter, payload.q);
      const params = {
        q: payload["q"],
      };

      if (min) {
        params["before"] = min;
      }

      const getsKey = `${key}${JSON.stringify(params)}`;
      if (!state.gets[getsKey]) {
        commit("setGets", getsKey);
        const response = await axios.get(url, { params: params });

        if (response.data.length > 0) {
          response.data.forEach((user) => {
            commit("add", user);
          });
          const ids = response.data.map((user) => user.id);
          commit("addToView", { key: key, ids: ids });
          commit("setLastView", key);
        }

        const more = response.headers.more === "true";
        commit("setMore", {
          key: key,
          value: more,
        });
      }
    },

    async getMutingIds({ commit, rootState }) {
      const response = await axios.get(
        `/attendee/hubs/${rootState.hub.current.slug}/muting_ids.json`
      );

      commit("setMutingIds", response.data);
    },

    async getFollowing({ commit, rootState }) {
      const response = await axios.get(
        `/attendee/hubs/${rootState.hub.current.slug}/following_ids.json`
      );

      commit("setFollowingIds", response.data);
    },

    async follow({ commit, rootState }, id) {
      await axios.post(
        `/attendee/hubs/${rootState.hub.current.slug}/followings.json`,
        {
          following: {
            user_id: id,
          },
          authenticity_token: rootState.authenticityToken,
        }
      );

      commit("setFollowing", id);
    },

    async unfollow({ commit, rootState }, id) {
      await axios.delete(
        `/attendee/hubs/${rootState.hub.current.slug}/followings/${id}.json`,
        {
          data: {
            authenticity_token: rootState.authenticityToken,
          },
        }
      );
      commit("removeFollowingId", id);
    },

    liveUpdate({ commit, rootState, getters }, user) {
      const min = getters["min"]();
      if (user.participation_id >= min) {
        commit("update", user);
      }
    },

    liveHide({ commit, rootState }, userId) {
      commit("hide", userId);
    },

    async toggleSupportAccess({ commit, state, rootState }) {
      let response = await axios.patch(`/profile.json`, {
        profile: {
          support_access: (!state.current.support_access).toString(),
        },
        authenticity_token: rootState.authenticityToken,
      });
      commit("set", response.data);
    },
  },
};
