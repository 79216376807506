import axios from "axios";
import Vue from "vue";
import lowerCase from "voca/lower_case";
import snakeCase from "voca/snake_case";
import pluralize from "pluralize";

import find from "lodash/find";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";

export default function (modelName, options = {}) {
  const paramName = snakeCase(modelName);
  const urlPlural = pluralize(paramName);

  const namespacedKey = options.namespacedKey || false;
  const singularNamespaceKey = options.singularNamespaceKey || "";
  const pluralNamespaceKey = options.pluralNamespaceKey || "";

  return {
    namespaced: true,
    state: {
      all: {},
    },
    mutations: {
      ...(options.mutations || {}),
      set(state, payload) {
        Vue.set(state.all, `${payload.id}`, {
          ...state.all[`${payload.id}`],
          ...payload,
        });
      },
      setAll(state, items) {
        items.forEach((item) => {
          if (item) {
            Vue.set(state.all, `${item.id}`, item);
          }
        });
      },
      delete(state, id) {
        Vue.delete(state.all, `${id}`);
      },
    },
    getters: {
      ...(options.getters || {}),
      all(state) {
        return Object.keys(state.all).map((key) => {
          return state.all[key];
        });
      },
      findAllBy: (state) => (predicate) => {
        return filter(state.all, predicate);
      },
      find: (state) => (id) => {
        return state.all[`${id}`];
      },
      findBy: (state) => (predicate) => {
        return find(state.all, predicate);
      },
      findAll: (state) => (ids) => {
        if (!ids || ids.length === 0) {
          return [];
        }
        return sortBy(
          filter(state.all, (item) => {
            return ids.includes(item.id);
          }),
          (item) => {
            return ids.indexOf(item.id);
          }
        );
      },
    },
    actions: {
      ...(options.actions || {}),
      async create({ commit, rootState }, attrs) {
        let url = `/hubs/${rootState.hub.current.slug}/${urlPlural}.json`;

        const params = {};
        params[paramName] = attrs;

        try {
          const response = await axios.post(url, params);
          const data = namespacedKey
            ? response.data[singularNamespaceKey]
            : response.data;
          commit("set", data);
          return data;
        } catch (e) {
          if (e.response) {
            return e.response.data;
          }
          console.error(e);
        }

        commit("set", response.data);

        return response.data;
      },

      async get({ commit, rootState }, id) {
        let url = `/hubs/${rootState.hub.current.slug}/${urlPlural}/${id}.json`;

        const response = await axios.get(url);
        const data = namespacedKey
          ? response.data[singularNamespaceKey]
          : response.data;

        commit("set", data);
        return data;
      },

      async all({ commit, rootState }) {
        let url = `/hubs/${rootState.hub.current.slug}/${urlPlural}.json`;

        const response = await axios.get(url);

        if (namespacedKey) {
          commit("setAll", response.data[pluralNamespaceKey]);
        } else {
          commit("setAll", response.data);
        }
      },

      async update({ commit, state, getters, rootState }, payload) {
        const url = `/hubs/${rootState.hub.current.slug}/${urlPlural}/${payload.id}.json`;
        const params = {};
        params[paramName] = payload.attrs;
        try {
          const response = await axios.put(url, params);
          const data = namespacedKey
            ? response.data[singularNamespaceKey]
            : response.data;
          commit("set", data);
          return data;
        } catch (e) {
          if (e.response) {
            return e.response.data;
          }
          console.error(e);
        }
        commit("set", response.data);
        return response.data;
      },

      async destroy({ commit, state, getters, rootState }, id) {
        const url = `/hubs/${rootState.hub.current.slug}/${urlPlural}/${id}.json`;
        try {
          const response = await axios.delete(url);
          commit("delete", id);
          return response;
        } catch (e) {
          return e.response;
        }
      },
    },
  };
}
