import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    current: null,
  },
  mutations: {
    setAll(state, permissions) {
      state.current = permissions;
    },
    set(state, permission){
      state.current.push(permission);
    },
    destroy(state, id){
      let index = state.current.findIndex(permission => permission.id == id);
      state.current.splice(index, 1);
    }
  },
  getters: {
    permittedTo: (state) => (action, resource_id, resource_type) => {
      let permission = state.current.find((permission) => {
        return (
          permission.action == action &&
          permission.resource_id == resource_id &&
          permission.resource_type == resource_type
        );
      });
      if (permission) {
        return true;
      } else {
        return false;
      }
    },
    unpermittedTo: (state, getters) => (action, resource_id, resource_type) => {
      return !getters["permittedTo"](action, resource_id, resource_type);
    },
  },
  actions: {
    async create({ commit, rootState }, permission) {
      let url = `/hubs/${rootState.hub.current.slug}/permissions.json`;

      try {
        const response = await axios.post(url, permission);
        return response.data;
      } catch (e) {
        if (e.response) {
          return e.response.data;
        }
        console.error(e);
      }
    },
  },
};
