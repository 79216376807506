import createStoreModule from "shared/lib/createStoreModule.js";
import sortable from "shared/lib/sortable.js";
import filter from "lodash/filter";
import sortBy from "lodash/filter";
export default createStoreModule("card", {
  ...sortable("card", {
    getters: {
      byTypes: (state, getters) => (card_type_ids) => {
        return getters["sortedFindAllBy"]((card) => {
          return card_type_ids.includes(card.card_type_id);
        });
      },
    },
  }),
});
