export default function (pageId, parentId) {
  let key = "hub";
  if (pageId) {
    key = `${key}:page:${pageId}`;
  }
  if (parentId) {
    key = `${key}:parent:${parentId}`;
  }
  return key;
}
