import axios from "axios";
import Vue from "vue";

import trim from "lodash/trim";

export default {
  namespaced: true,
  state: {},

  mutations: {
    set(state, details) {
      Vue.set(state, trim(details.key, "/"), details);
    },
  },

  actions: {
    async fetch({ commit, getters, dispatch, rootState }, payload) {
      const response = await axios.post("/open_graph_lookups", {
        url: payload.url,
        hub_slug: payload.hubSlug,
      });
      if (!response.data["wait"]) {
        commit("set", response.data);
      }
    },
  },
};
