import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import { createStore } from "vuex-extensions";

import axios from "axios";

import av from "./modules/av.js";
import cardType from "./modules/cardType.js";
import block from "./modules/block.js";
import card from "./modules/card.js";
import event from "./modules/event.js";
import livestream from "./modules/livestream.js";
import participation from "./modules/participation.js";
import hub from "./modules/hub.js";
import image from "./modules/image.js";
import notification from "./modules/notification.js";
import page from "./modules/page.js";
import post from "./modules/post.js";
import plan from "./modules/plan.js";
import permission from "./modules/permission.js";
import room from "./modules/room.js";
import space from "./modules/space.js";
import user from "./modules/user.js";
import video from "./modules/video.js";

import openGraphLookup from "./modules/openGraphLookup.js";

const containerInitial = {
  width: null,
  height: null,
  breakpoint: null,
  wide: null,
  narrow: null,
  gt80ch: null,
  lt80ch: null,
};

const state = {
  authenticityToken: null,
  isEditing: false,
  time_zones: [],
  postsListWidth: null,
  collaboration: false,
  customerAssumed: false,
  invitedCollaborationsCount: null,
  mainContainer: containerInitial,
  scrollContainer: { width: null, height: null },
  onlineParticipantsCount: null,
  participantsCount: null,
  postsContainer: containerInitial,
  visibleParticipantsCount: null,
  vitoClientVersion: null,
  vitoServerVersion: null,
  viewportWidth: null,
  playingPlyr: null,
  showDesktopSidebar: true,
};

const mutations = {
  setEditing(state, truth) {
    state.isEditing = truth;
  },
  setMainContainer(state, container) {
    Vue.set(state, "mainContainer", container);
  },
  setScrollContainer(state, container) {
    Vue.set(state, "scrollContainer", container);
  },
  setPostsContainer(state, container) {
    Vue.set(state, "postsContainer", container);
  },
  setViewportWidth(state, width) {
    state.viewportWidth = width;
  },
  setPostsListWidth(state, width) {
    state.postsListWidth = width;
  },
  setInvitedCollaborationsCount(state, count) {
    state.invitedCollaborationsCount = count;
  },
  setOnlineParticipantsCount(state, count) {
    state.onlineParticipantsCount = count;
  },
  setParticipantsCount(state, payload) {
    state.participantsCount = payload.participants_count;
    state.visibleParticipantsCount = payload.visible_participants_count;
  },
  setPlayingPlyr(state, plyr) {
    if (state.playingPlyr && plyr != state.playingPlyr) {
      state.playingPlyr.destroy();
    }
    state.playingPlyr = plyr;
  },
  setup(state, payload) {
    state.authenticityToken = payload.authenticityToken;
    state.time_zones = payload.time_zones;
    state.collaboration = payload.collaboration;
    state.customerAssumed = payload.customerAssumed;
    state.onlineParticipantsCount = payload.onlineParticipantsCount;
    state.participantsCount = payload.participantsCount;
    state.visibleParticipantsCount = payload.visibleParticipantsCount;
    state.vitoClientVersion = payload.vitoClientVersion;
    state.vitoServerVersion = payload.vitoClientVersion;
  },
  setNewVersion(state, payload) {
    if (state.vitoServerVersion != payload.version) {
      state.vitoServerVersion = payload.version;
      console.log("New version available", payload.version);
    }
  },
  toggleEditing(state) {
    state.isEditing = !state.isEditing;
  },
  setShowDesktopSidebar(state, truth) {
    state.showDesktopSidebar = truth;
  },
};

const getters = {
  customerAssumed(state) {
    return state.customerAssumed;
  },
  newVersionAvailable(state) {
    return state.vitoClientVersion != state.vitoServerVersion;
  },
  vitoClientVersion(state) {
    return state.vitoClientVersion;
  },
  vitoServerVersion(state) {
    return state.vitoServerVersion;
  },
};

const modules = {
  av,
  hub,
  block,
  card,
  cardType,
  event,
  image,
  participation,
  permission,
  plan,
  user,
  livestream,
  notification,
  openGraphLookup,
  page,
  post,
  room,
  space,
  video,
};

const actions = {
  initialize({ commit, state, dispatch }, data) {
    commit("hub/set", data.hub);
    commit("image/setAll", data.images);
    commit("plan/set", data.plan);
    commit("setInvitedCollaborationsCount", data.invited_collaborations_count);
    commit("setup", {
      agenda: data.agenda,
      authenticityToken: data.authenticity_token,
      time_zones: data.time_zones,
      collaboration: data.collaboration,
      onlineParticipantsCount: data.online_participations_count,
      participantsCount: data.participants_count,
      visibleParticipantsCount: data.visible_participants_count,
      vitoClientVersion: data.vito_client_version,
      customerAssumed: data.customer_assumed,
    });
    commit("card/setAll", data.cards);
    commit("cardType/setAll", data.card_types);
    commit("event/setAll", data.events);
    commit("user/set", data.current_user);
    commit("participation/set", data.participation);
    commit("card/setAll", data.cards);
    commit("livestream/setAll", data.livestreams);
    commit("video/setAll", data.videos);
    commit("page/setAll", data.pages);
    if (state.participation.current) {
      commit("permission/setAll", data.permissions);
      dispatch("user/getFollowing");
      dispatch("user/getMutingIds");
      if (state.collaboration) {
        commit("page/setAll", data.pages);
      }
    }
  },
};

export default new createStore(Vuex.Store, {
  state,
  mutations,
  getters,
  actions,
  modules,
});
