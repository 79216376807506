import axios from "axios";
import createStoreModule from "shared/lib/createStoreModule.js";
import sortable from "shared/lib/sortable.js";
import filter from "lodash/filter";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import Vue from "vue";

export default createStoreModule("page", {
  ...sortable("page", {
    mutations: {},
    getters: {
      bySlug: (state) => (id) => {
        return (
          state.all[`${id}`] ||
          find(state.all, (page) => {
            return page.slug === id || page.uuid === id;
          })
        );
      },
      children: (state, getters) => (parent_id) => {
        return filter(getters["sorted"], { parent_id: parseInt(parent_id) });
      },
      options(state, getters) {
        return [{ label: "Root", id: "" }].concat(
          filter(getters["roots"], (page) => {
            return ["Page::Default", "Section"].includes(page.type);
          }).map((page) => {
            return { label: page.title, id: page.id };
          })
        );
      },
      roots(state, getters) {
        return filter(getters["sorted"], (page) => {
          return page.parent_id === null;
        });
      },
      sidebarRoots(state, getters) {
        return filter(getters["roots"], (page) => {
          return page.display_in_sidebar;
        });
      },
      withStagedChanges(state, getters) {
        return filter(state.all, (page) => {
          return !!page.draft;
        });
      },
      withStagedChangesExcludingDrafts(state, getters) {
        return filter(getters["withStagedChanges"], (page) => {
          return page.state != "draft";
        });
      },
    },
    actions: {
      async batchPublish({ commit, state, getters, rootState }, params) {
        const url = `/hubs/${params.slug}/pages/batch_publish.json`;
        await axios.post(url, { exclude_drafts: params.exclude_drafts });
      },
      async publish({ commit, state, getters, rootState }, params) {
        const url = `/hubs/${params.slug}/pages/${params.page_id}/publish.json`;
        const response = await axios.post(url);
        commit("set", response.data);
        return response.data;
      },
    },
  }),
});
