import axios from "axios";
import Vue from "vue";
import store from "hub/store";
import orderBy from "lodash/orderBy";
import min from "lodash/min";

export default {
  namespaced: true,
  state: {
    all: {},
    filter: "all",
    more: {
      all: true,
      announcements: true,
      mentions_and_replies: true,
      faves: true,
      followings: true,
    },
    minIds: {},
  },

  mutations: {
    set(state, payload) {
      const mutingIds = store.state.user.mutingIds;
      if (!mutingIds.includes(payload.source_id)) {
        Vue.set(state.all, `${payload.id}`, payload);
        if (!payload.read_at) {
          store.commit("participation/setNotificationAlert", true);
        }
      }
    },

    remove(state, payload) {
      Vue.delete(state.all, payload.id);
    },

    setFilter(state, payload) {
      state.filter = payload;
    },
  },

  getters: {
    sorted(state) {
      const lookup = {};
      const list = [];
      orderBy(state.all, "id", ["desc"])
        .filter((notification) => {
          if (notification.id < state.minIds[state.filter]) {
            return false;
          }
          switch (state.filter) {
            case "all":
              return true;
            case "announcements":
              return (
                notification.subject_type == "Post" && !notification.user_id
              );
            case "mentions_and_replies":
              return (
                notification.subject_type == "Post" && notification.user_id
              );
            case "faves":
              return notification.subject_type == "Fave";
            case "followings":
              return notification.subject_type == "Following";
          }
        })
        .forEach((n) => {
          if (lookup[n.group_key]) {
            lookup[n.group_key].related.push(n);
          } else {
            n.related = [];
            list.push(n);
            lookup[n.group_key] = n;
          }
        });
      return list;
    },

    more(state) {
      return state.more[state.filter];
    },

    filter(state) {
      return state.filter;
    },
  },

  actions: {
    async all({ commit, state, getters, rootState }, payload) {
      if (!state.more[state.filter]) {
        return;
      }
      const url = `/attendee/hubs/${rootState.hub.current.slug}/my/notifications.json`;
      let params = {};
      if (state.filter != "all") {
        params.filter = state.filter;
      }
      if (state.minIds[state.filter]) {
        params.before = state.minIds[state.filter];
      }
      const response = await axios.get(url, { params: params });

      if (response.data.length > 0) {
        response.data.forEach((notification) => {
          commit("set", notification);
        });
        const ids = response.data.map((n) => n.id);
        state.minIds[state.filter] = min(ids);
      }
      state.more[state.filter] = response.headers.more === "true";
    },
  },
};
