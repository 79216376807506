import Vue from "vue";

export default {
  namespaced: true,
  state: {
    current: null,
    participants: {},
    tracks: {},
    screenshare: null,
  },
  mutations: {
    set(state, room) {
      state.current = room;
    },
    setParticipants(state, participants) {
      Vue.set(state, "participants", participants);
    },
    setTracks(state, tracks) {
      Vue.set(state, "tracks", tracks);
    },
    setScreenshare(state, screenshare) {
      Vue.set(state, "screenshare", screenshare);
    },
    addParticipant(state, participant) {
      Vue.set(state.participants, participant.identity, participant);
    },
    deleteParticipant(state, participant) {
      Vue.delete(state.participants, participant.identity);
    },
    addTrack(state, payload) {
      if (!state.tracks[payload.participant.identity]) {
        Vue.set(state.tracks, payload.participant.identity, {});
      }
      Vue.set(
        state.tracks[payload.participant.identity],
        payload.track.source,
        payload.track
      );
    },
  },
};
