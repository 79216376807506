import appStorage from "lib/localStorage.js";
import Vue from "vue";

const settings = appStorage.get("avSettings", {
  default: {
    enableAutoGainControl: true,
    audioDeviceId: null,
    audioEnabled: true,
    videoDeviceId: null,
    videoEnabled: true,
  },
});

export default {
  namespaced: true,
  state: {
    settings,
    localAudioTrack: null,
    localVideoTrack: null,
  },
  mutations: {
    set(state, settings) {
      Vue.set(state, "avSettings", settings);
    },
    setLocalAudioTrack(state, value) {
      Vue.set(state, "localAudioTrack", value);
    },
    setLocalVideoTrack(state, value) {
      Vue.set(state, "localVideoTrack", value);
    },
  },
};
