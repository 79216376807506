import createStoreModule from "shared/lib/createStoreModule.js";

import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

export default createStoreModule("image", {
  getters: {
    prev: (state, getters, rootState) => (original) => {
      return orderBy(
        filter(state.all, (image) => {
          return image.id > original.id;
        }),
        "id",
        ["asc"]
      )[0];
    },
    next: (state, getters, rootState) => (original) => {
      return orderBy(
        filter(state.all, (image) => {
          return image.id < original.id;
        }),
        "id",
        ["desc"]
      )[0];
    },
  },
});
