import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    current: null,
  },
  mutations: {
    set(state, participation) {
      state.current = participation;
    },

    setNotificationAlert(state, truth) {
      Vue.set(state.current, "show_notification_alert", truth);
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
  },
  actions: {
    async invite({ commit, rootState }, invitation) {
      try {
        const url = `/admin/hubs/${rootState.hub.current.slug}/participations/invites.json`;
        const response = await axios.post(url, { invitation: invitation });
        return response.data;
      } catch (e) {
        if (e.response) {
          return e.response.data;
        }
        console.error(e);
      }
    },

    async update({ commit, rootState }, participation) {
      const response = await axios.patch(
        `/attendee/hubs/${rootState.hub.current.slug}/participation.json`,
        {
          participation: participation,
          authenticity_token: rootState.authenticityToken,
        }
      );
      commit("set", response.data);
    },

    async leave({ commit, rootState }) {
      const response = await axios.patch(
        `/attendee/hubs/${rootState.hub.current.slug}/participation/leave.json`,
        {
          authenticity_token: rootState.authenticityToken,
        }
      );
      commit("set", response.data);
    },

    async rejoin({ commit, rootState }) {
      const response = await axios.patch(
        `/attendee/hubs/${rootState.hub.current.slug}/participation/rejoin.json`,
        {
          authenticity_token: rootState.authenticityToken,
        }
      );
      commit("set", response.data);
    },
  },
};
