<template></template>
<script>
import axios from "axios";
import store from "hub/store";

export default {
  store,
  data() {
    return {
      colorScheme: null,
      hubColorScheme: null,
      userColorScheme: null,
      systemColorScheme: null,
    };
  },

  async created() {
    this.hubColorScheme = this.getHubColorScheme();
    this.systemColorScheme = this.getSystemColorScheme();
    this.userColorScheme = this.getUserColorScheme();
    this.setColorScheme();

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", this.setSystemColorScheme);
  },

  watch: {
    colorScheme() {
      this.applyColorScheme();
    },
    "storeHub.color_scheme"() {
      if (this.storeHub) {
        this.hubColorScheme = this.storeHub.color_scheme;
        this.setColorScheme();
      }
    },
    "storeUser.color_scheme"() {
      if (this.storeUser) {
        this.userColorScheme = this.storeUser.color_scheme;
        this.setColorScheme();
      }
    },
  },

  computed: {
    storeHub() {
      return this.$store.getters["hub/current"];
    },

    storeUser() {
      return this.$store.getters["user/current"];
    },
  },

  methods: {
    getHubColorScheme() {
      try {
        const pageDataElementId = document.getElementById
          ? "cached-page-data"
          : "page-data";
        return JSON.parse(document.getElementById(pageDataElementId).innerHTML)[
          "hub"
        ]?.color_scheme;
      } catch (e) {
        return false;
      }
    },

    getSystemColorScheme() {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
      }
      return "light";
    },

    getUserColorScheme() {
      try {
        return JSON.parse(document.getElementById("page-data").innerHTML)[
          "current_user"
        ]?.color_scheme;
      } catch (e) {
        return false;
      }
    },

    setColorScheme() {
      if (this.userColorScheme && this.userColorScheme != "hub_default") {
        this.colorScheme =
          this.userColorScheme == "system"
            ? this.systemColorScheme
            : this.userColorScheme;
      } else if (this.hubColorScheme) {
        this.colorScheme =
          this.hubColorScheme == "system"
            ? this.systemColorScheme
            : this.hubColorScheme;
      } else if (this.systemColorScheme == "dark") {
        this.colorScheme = "dark";
      } else {
        this.colorScheme = "light";
      }
    },

    setSystemColorScheme() {
      this.colorScheme = this.getSystemColorScheme();
    },

    applyColorScheme() {
      const body = document.querySelector("body");
      if (this.colorScheme === "dark") {
        body.classList = "dark";
        body.style = `color-scheme: dark`;
      } else {
        body.classList = "";
        body.style = "";
      }
    },
  },
};
</script>
