const prefix = "vito-";
export default {
  get(key, options = {}) {
    const val = localStorage[prefix + key];
    if (val) {
      try {
        return JSON.parse(val);
      } catch (e) {
        return options.default;
      }
    }
    return options.default;
  },
  set(key, val) {
    localStorage[prefix + key] = JSON.stringify(val);
  },
};
