import axios from "axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    current: null,
  },
  mutations: {
    set(state, plan) {
      state.current = plan;
    },
  },
  getters: {
    can: (state) => (action) => {
      let actions = state.current.actions
      if (actions.hasOwnProperty(action)) {
        return actions[action]
      } else {
        return true
      }
    },
    cannot: (state, getters) => (action) => {
      return !getters["can"](action)
    },
  },
  actions: {
  },
};
