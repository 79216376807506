import axios from "axios";
import Vue from "vue";

import filter from "lodash/filter";
import sortBy from "lodash/sortBy";

export default function (modelName, options = {}) {
  return {
    mutations: {
      ...(options.mutations || {}),
      sort(state, ids) {
        ids.forEach((id, index) => {
          Vue.set(
            state.all,
            `${id}`,
            Object.assign({ ...state.all[`${id}`] }, { position: index })
          );
        });
      },
    },

    getters: {
      ...(options.getters || {}),
      sorted: (state) => {
        return sortBy(state.all, "position");
      },

      sortedFindAll: (state) => (ids) => {
        if (!ids || ids.length === 0) {
          return [];
        }
        return sortBy(
          filter(state.all, (item) => {
            return ids.includes(item.id);
          }),
          "position"
        );
      },

      sortedFindAllBy: (state) => (predicate) => {
        return sortBy(filter(state.all, predicate), "position");
      },
    },

    actions: {
      ...(options.actions || {}),
      async sort({ commit, state, getters, rootState }, sort) {
        commit("sort", sort);
        const url = `/hubs/${rootState.hub.current.slug}/${modelName}s/sort.json`;
        axios.put(url, { sort: sort });
      },
    },
  };
}
