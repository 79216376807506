import createStoreModule from "shared/lib/createStoreModule.js";
import axios from "axios";

export default createStoreModule("video", {
  actions: {
    async createDownloads({ commit, rootState }, id) {
      let url = `/admin/hubs/${rootState.hub.current.slug}/videos/${id}/downloads.json`;
      const response = await axios.post(url);
      return response;
    },
    async getDownloads({ commit, rootState }, id) {
      let url = `/admin/hubs/${rootState.hub.current.slug}/videos/${id}/downloads.json`;
      const response = await axios.get(url);
      return response;
    },
  },
});
