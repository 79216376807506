import axios from "axios";
import Vue from "vue";
import store from "hub/store";

import max from "lodash/max";
import filter from "lodash/filter";
import reject from "lodash/reject";
import orderBy from "lodash/orderBy";
import uniq from "lodash/uniq";

import addToUniqueArray from "shared/lib/addToUniqueArray";
import draftKey from "lib/draftKey.js";

export default {
  namespaced: true,
  state: {
    draft: {},
    all: {},
    cache: {},
  },
  mutations: {
    setDraft(state, payload) {
      const key = draftKey(payload.page_id, payload.parent_id);
      Vue.set(state.draft, key, payload);
    },
    add(state, post) {
      Vue.set(state.all, `${post.id}`, post);
    },
    destroy(state, postId) {
      Vue.delete(state.all, `${postId}`);
    },
    setAll(state, payload) {
      payload.posts.forEach((post) => {
        if (!post.parent_id) {
          Vue.set(state.all, `${post.id}`, post);
        }
      });
    },
  },

  getters: {
    children: (state, getters, rootState) => (parent_id) => {
      return orderBy(
        filter(state.all, (post) => {
          return (
            post.parent_id == parent_id &&
            !(rootState.user.mutingIds || []).includes(post.user.id)
          );
        }),
        "id",
        ["asc"]
      );
    },
    communityFaves(state, getters, rootState) {
      return filter(getters["sortedRoots"], (post) => {
        return (
          post.user.id == rootState.user.current.id && post.faves.length > 0
        );
      });
    },

    communityFavesByPage: (state, getters, rootState) => (pageId) => {
      return filter(getters["communityFaves"], {
        page_id: parseInt(pageId),
      });
    },

    find: (state) => (id) => {
      return state.all[`${id}`];
    },

    key: (state, getters) => (scope, filter, page) => {
      const keyParts = [];
      keyParts.push(scope);
      keyParts.push(filter);
      if (page) {
        keyParts.push(page.id);
      }
      return keyParts.join(".");
    },
    mentions(state, getters, rootState) {
      return filter(getters["sortedRoots"], (post) => {
        return post.mentions.indexOf(rootState.user.current.username) >= 0;
      });
    },
    filteredRoots(state, getters, rootState) {
      return filter(state.all, (post) => {
        return (
          post.parent_id === null &&
          !(rootState.user.mutingIds || []).includes(post.user.id)
        );
      });
    },
    sortedRoots(state, getters) {
      return orderBy(getters["filteredRoots"], "id", ["desc"]);
    },
    byPage: (state, getters) => (pageId) => {
      return filter(getters["sortedRoots"], { page_id: parseInt(pageId) });
    },
    followed(state, getters, rootState, rootGetters) {
      return filter(getters["sortedRoots"], (post) => {
        return rootGetters["user/followingIdsIncludingSelf"].includes(
          post.user.id
        );
      });
    },
    byParticipant: (state, getters) => (userId) => {
      return filter(getters["sortedRoots"], (post) => {
        return post.user.id == parseInt(userId);
      });
    },
    followedByPage: (state, getters) => (pageId) => {
      return filter(getters["followed"], {
        page_id: parseInt(pageId),
      });
    },

    myFaves(state, getters, rootState) {
      return filter(getters["sortedRoots"], (post) => {
        return post.faves.indexOf(rootState.user.current.id) >= 0;
      });
    },

    myFavesByPage: (state, getters, rootState) => (pageId) => {
      return filter(getters["myFaves"], {
        page_id: parseInt(pageId),
      });
    },

    view: (state, getters) => (scope, filter, page) => {
      if (scope === "all") {
        if (filter === "communityFaves") {
          if (page) {
            return getters["communityFavesByPage"](page.id);
          }
          return getters["communityFaves"];
        }
        if (filter === "followed") {
          if (page) {
            return getters["followedByPage"](page.id);
          }
          return getters["followed"];
        }
        if (filter === "myFaves") {
          if (page) {
            return getters["myFavesByPage"](page.id);
          }
          return getters["myFaves"];
        }
        if (page) {
          return getters["byPage"](page.id);
        }
        return getters["sortedRoots"];
      } else if (scope === "mentions") {
        return getters["mentions"];
      } else if (scope === "participant") {
        const userId = filter;
        return getters["byParticipant"](userId);
      }
    },
    url: (state, getters, rootState) => (scope, filter, page) => {
      let basePath = `/attendee/hubs/${rootState.hub.current.slug}`;
      if (scope == "mentions") {
        return `${basePath}/my/mentions.json`;
      }
      if (scope == "participant") {
        const userId = filter;
        return `${basePath}/participants/${userId}/posts.json`;
      }
      if (scope == "all") {
        if (page) {
          basePath = `${basePath}/pages/${page.slug}`;
        }
        if (filter === "communityFaves") {
          return `${basePath}/posts/community_faves.json`;
        }
        if (filter === "followed") {
          return `${basePath}/posts/followed.json`;
        }
        if (filter === "myFaves") {
          return `${basePath}/posts/my_faves.json`;
        }
        if (page) {
          return `/hubs/${rootState.hub.current.slug}/pages/${page.slug}/posts.json`;
        } else {
          return `/hubs/${rootState.hub.current.slug}/posts.json`;
        }
      }
    },
    userIds(state, getters) {
      return uniq(
        Object.keys(state.all).map((key) => {
          return state.all[key].user.id;
        })
      );
    },
  },

  actions: {
    async create({ commit, getters, dispatch, rootState }, payload) {
      const post = payload.post;
      const page = payload.page;
      let url;
      if (page) {
        url = `/attendee/hubs/${rootState.hub.current.slug}/pages/${page.slug}/posts.json`;
      } else {
        url = `/attendee/hubs/${rootState.hub.current.slug}/posts.json`;
      }
      try {
        const response = await axios.post(url, {
          post: post,
          authenticity_token: rootState.authenticityToken,
        });
        commit("add", response.data);
        return response.data;
      } catch (e) {
        if (e.response) {
          return e.response.data;
        }
        console.error(e);
      }
    },

    async getAll({ commit, getters, dispatch, rootState }, params = {}) {
      const url = `/hubs/${rootState.hub.current.slug}/posts.json`;
      const response = await axios.get(url, { params: params });
      if (response.data.length > 0) {
        const posts = response.data;
        posts.forEach((post) => {
          commit("add", post);
        });
      }
      return response;
    },

    async getChildren({ commit, rootState }, parentId) {
      const response = await axios.get(
        `/hubs/${rootState.hub.current.slug}/posts/${parentId}/children.json`
      );
      const posts = response.data;
      posts.forEach((post) => {
        commit("add", post);
      });
    },

    async destroyPost({ commit, state, getters, rootState }, postId) {
      if (state.all[postId]) {
        commit("hub/decrementPostCount", null, { root: true });
      }
      commit("destroy", postId);
    },

    async all(
      { commit, state, getters, dispatch, rootState },
      payload = { scope: "all" }
    ) {
      const url = getters["url"](payload.scope, payload.filter, payload.page);
      const key = getters["key"](payload.scope, payload.filter, payload.page);

      const params = {};

      if (payload.min) {
        params["before"] = payload.min;
      }

      const getResponse = async function (url, params) {
        const cacheKey = url + JSON.stringify(params);
        if (params["before"] && state.cache[cacheKey]) {
          return state.cache[cacheKey];
        }
        const response = axios.get(url, { params: params });
        if (params["before"]) {
          state.cache[cacheKey] = await response;
        }
        return response;
      };

      const response = await getResponse(url, params);
      if (response.data.length > 0) {
        const posts = response.data;
        posts.forEach((post) => {
          commit("add", post);
        });
      }

      return response;
    },

    async get({ commit, state, getters, dispatch, rootState }, postId) {
      const url = `/hubs/${rootState.hub.current.slug}/posts/${postId}.json`;
      try {
        const response = await axios.get(url);
        const post = response.data;
        commit("add", post);
        return post;
      } catch (e) {
        if (e.message == "Request failed with status code 404") {
          return null;
        }
        throw e;
      }
    },
  },
};
