import createStoreModule from "shared/lib/createStoreModule.js";

import axios from "axios";
import filter from "lodash/filter";
import some from "lodash/some";

export default createStoreModule("livestream", {
  getters: {
    anyLive(state, getters) {
      return some(getters["live"], (livestream) => {
        return livestream.show_video_player;
      });
    },
    anyPreview(state, getters) {
      return some(getters["live"], (livestream) => {
        return !livestream.show_video_player;
      });
    },
    live(state) {
      return filter(state.all, { status: "active" });
    },
  },
  actions: {
    async regenerate({ commit, rootState }, id) {
      let url = `/hubs/${rootState.hub.current.slug}/livestreams/${id}/regenerate.json`;
      const response = await axios.patch(url);
      commit("set", response.data);
    },
    async updateShowVideoPlayer({ commit, rootState }, params) {
      let url = `/hubs/${rootState.hub.current.slug}/livestreams/${params.id}.json`;
      const response = await axios.patch(url, {
        livestream: {
          show_video_player: params.truth,
        },
      });
      commit("set", response.data);
    },
  },
});
