import Vue from "vue";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    current: null,
  },
  mutations: {
    set(state, hub) {
      Vue.set(state, "current", hub);
    },
    decrementPostCount(state) {
      Vue.set(state.current, "posts_count", state.current.posts_count - 1);
    },
    incrementPostCount(state) {
      Vue.set(state.current, "posts_count", state.current.posts_count + 1);
    },

    removeIntegration(state, integration) {
      state.current.integrations[integration] = null;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    isOwner: (state, getters, rootState, rootGetters) => (userId) => {
      return getters["current"].owner_id == userId;
    },
  },
  actions: {
    async create({ commit, rootState }, attrs) {
      let url = `/admin/hubs.json`;

      const params = {};
      params["hub"] = attrs;

      try {
        const response = await axios.post(url, params);
        return response.data;
      } catch (e) {
        if (e.response) {
          return e.response.data;
        }
        console.error(e);
      }

      return response.data;
    },

    async get({ commit, state, getters, rootState }, params) {
      const url = `/admin/hubs/${rootState.hub.current.slug}.json`;
      const response = await axios.get(url);
      commit("set", response.data);
      return response.data;
    },

    async getExtended(
      { commit, dispatch, state, getters, rootState },
      hubSlug
    ) {
      console.log("getting extended");
      const url = `/hubs/${hubSlug}/preload_cache.json`;
      const response = await axios.get(url);
      dispatch("initialize", response.data, { root: true });
      return response.data;
    },

    async update({ commit, state, getters, rootState }, params) {
      const url = `/admin/hubs/${rootState.hub.current.slug}.json`;
      try {
        const response = await axios.put(url, { hub: params.hub });
        commit("set", response.data);
        return response.data;
      } catch (e) {
        if (e.response) {
          return e.response.data;
        }
        console.error(e);
      }
    },
  },
};
