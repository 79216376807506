import fz from "fuzzaldrin-plus";

class Search {

  constructor(items, scorableAttributes){
    this.items = items;
    this.scorableAttributes = scorableAttributes
  }

  perform(query){
    if (query){
      const scores = this.scoreItems(query);

      return this.items
        .filter((item) => scores[item.id] > 1)
        .sort((a, b) => scores[b.id] - scores[a.id]);
    } else {
      return this.items
    }
  }

  scoreItems(query) {
    const preparedQuery = fz.prepareQuery(query);
    const scores = {};

    this.items.map((item, index) => {
      const scoredAttributes = this.scorableAttributesForItem(item).map(
        (attribute) => {
          if (attribute) {
            return fz.score(attribute, query, { preparedQuery });
          } else {
            return 0;
          }
        }
      );

      scores[item.id] = Math.max(...scoredAttributes);
      return item;
    });

    return scores;
  }

  scorableAttributesForItem(item) {
    return this.scorableAttributes.map((field) => {
      return item[field];
    })
  }
}

export default Search;
