import createStoreModule from "shared/lib/createStoreModule.js";
import axios from "axios";

export default createStoreModule("space", {
  namespacedKey: true,
  singularNamespaceKey: "space",
  pluralNamespaceKey: "spaces",

  state: {
    rooms: {},
  },

  actions: {
    async startBroadcast({ commit, rootState }, id) {
      let url = `/hubs/${rootState.hub.current.slug}/spaces/${id}/start_broadcast.json`;
      const response = await axios.post(url);
    },
    async stopBroadcast({ commit, rootState }, id) {
      let url = `/hubs/${rootState.hub.current.slug}/spaces/${id}/stop_broadcast.json`;
      const response = await axios.post(url);
    },
  },
});
