import createStoreModule from "shared/lib/createStoreModule.js";
import sortable from "shared/lib/sortable.js";
import find from "lodash/find";
import filter from "lodash/filter";
import reject from "lodash/reject";
import sortBy from "lodash/sortBy";
import Vue from "vue";

import { isAfter, isSameMinute, isWithinInterval, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const cache = { current: {}, next: {} };

export default createStoreModule("event", {
  ...sortable("event", {
    mutations: {
      updateTimes(state, vals) {
        const keys = Object.keys(vals);
        keys.forEach((key) => {
          Vue.set(state.all[`${key}`], "starts_at", vals[key].starts_at);
          Vue.set(state.all[`${key}`], "ends_at", vals[key].ends_at);
          Vue.set(state.all[`${key}`], "duration", vals[key].duration);
        });
      },
    },
    getters: {
      current: (state, getters, rootState, rootGetters) => (time, timezone) => {
        const currentRoots = filter(getters["roots"], (event) => {
          if (!event.starts_at) return false;

          const startsAt = utcToZonedTime(parseISO(event.starts_at), timezone);

          if (!event.ends_at) {
            return isAfter(time, startsAt);
          } else {
            const endsAt = utcToZonedTime(parseISO(event.ends_at), timezone);
            return isWithinInterval(time, {
              start: startsAt,
              end: endsAt,
            });
          }
        });

        return currentRoots;

        // const out = reject(
        //   currentRoots.map((event) => {
        //     const children = getters["children"](event.id);
        //     if (children.length > 0) {
        //       return find(children, (event) => {
        //         const startsAt = utcToZonedTime(
        //           parseISO(event.starts_at),
        //           timezone
        //         );
        //         const endsAt = utcToZonedTime(
        //           parseISO(event.ends_at),
        //           timezone
        //         );
        //         return isWithinInterval(time, {
        //           start: startsAt,
        //           end: endsAt,
        //         });
        //       });
        //     } else {
        //       if (event.livestream_id) {
        //         const livestream = rootGetters["livestream/find"](
        //           event.livestream_id
        //         );
        //         if (livestream.status === "active") {
        //           return event;
        //         }
        //       }
        //     }
        //   }),
        //   (event) => {
        //     return !event;
        //   }
        // );

        // cache.current[time] = out;
        // return out;
      },
      next: (state, getters, rootState, rootGetters) => (time, timezone) => {
        // const current = getters["current"](time, timezone);
        let out = [];

        const rootsWithStartTimes = reject(getters["roots"], (event) => {
          return !event.starts_at;
        });

        const rootStartTimes = rootsWithStartTimes.map((event) => {
          const startsAt = utcToZonedTime(parseISO(event.starts_at), timezone);
          return startsAt;
        });

        const nextStartTime = find(rootStartTimes.sort(), (startsAt) => {
          return isAfter(startsAt, time);
        });

        const nextRoots = filter(rootsWithStartTimes, (event) => {
          const startsAt = utcToZonedTime(parseISO(event.starts_at), timezone);
          return isSameMinute(startsAt, nextStartTime);
        });

        out = nextRoots;

        // if (current) {
        //   current.forEach((currentEvent) => {
        //     if (currentEvent.parent_id) {
        //       const next = find(
        //         getters["children"](currentEvent.parent_id),
        //         (event) => {
        //           return event.position > currentEvent.position;
        //         }
        //       );
        //       out.push(next);
        //     }
        //   });
        // }

        return sortBy(out, "starts_at");
      },
      children: (state, getters) => (parent_id) => {
        return filter(getters["sorted"], { parent_id: parseInt(parent_id) });
      },
      roots(state, getters) {
        return filter(getters["sorted"], (event) => {
          return event.parent_id === null;
        });
      },
      rootsByDate(state, getters) {
        return sortBy(getters["roots"], "starts_at");
      },
    },
  }),
});
